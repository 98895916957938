import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	queueName: {
		id: 'servicedesk-queues-no-queues-view.queue-name',
		defaultMessage: 'Open issues',
		description: 'Name of the default queue with all open issues in it',
	},
	queueNameIssueTermRefresh: {
		id: 'servicedesk-queues-no-queues-view.queue-name-issue-term-refresh',
		defaultMessage: 'Open issues',
		description: 'Name of the default queue with all open issues in it',
	},
});
