/**
 * @generated SignedSource<<52df4069ce3b93c3591cf40a5bbec2fb>>
 * @relayHash 0a0f70aad0c891664a86b1da06771945
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 04a5def9ff0eb06d12d95482cb8856f684c1557a118fcd8066110bdffbd1334d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type uiFieldsPageQuery$variables = {
  cloudId: string;
  projectKey: string;
};
export type uiFieldsPageQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly isExplicitFieldAssociationsEnabled: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"fieldsTable_projectFields" | "pageHeader_projectFields_Header">;
};
export type uiFieldsPageQuery = {
  response: uiFieldsPageQuery$data;
  variables: uiFieldsPageQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcistmpprojectfieldsanalyticsupdatesenabledrelayprovider": isProjectFieldsAnalyticsUpdatesEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcistmpprojectfieldsanalyticsupdatesenabledrelayprovider: {
    readonly get: () => boolean;
  };
});

import isProjectFieldsAnalyticsUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/is-tmp-project-fields-analytics-updates-enabled.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "projectKey",
    "variableName": "projectKey"
  }
],
v4 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "isExplicitFieldAssociationsEnabled"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "key"
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiFieldsPageQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "fieldsTable_projectFields"
      },
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "pageHeader_projectFields_Header"
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcistmpprojectfieldsanalyticsupdatesenabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "uiFieldsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v10/*: any*/),
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "concreteType": "JiraFieldTypeGroupConnection",
                "kind": "LinkedField",
                "name": "projectFieldTypeGroups",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraFieldTypeGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraFieldTypeGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "displayName"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraProjectWithIssueTypeIds",
                "kind": "LinkedField",
                "name": "projectWithVisibleIssueTypeIds",
                "plural": false,
                "selections": [
                  {
                    "args": (v11/*: any*/),
                    "concreteType": "JiraFieldAssociationWithIssueTypesConnection",
                    "kind": "LinkedField",
                    "name": "fieldAssociationWithIssueTypes",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraFieldAssociationWithIssueTypesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraFieldAssociationWithIssueTypes",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraField",
                                "kind": "LinkedField",
                                "name": "field",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "fieldId"
                                  },
                                  (v9/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "scope"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "typeKey"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "typeName"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "description"
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "name": "hasMissingConfiguration"
                              },
                              {
                                "concreteType": "JiraIssueTypeConnection",
                                "kind": "LinkedField",
                                "name": "issueTypes",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v7/*: any*/),
                                          (v9/*: any*/),
                                          (v8/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraFieldOperation",
                                "kind": "LinkedField",
                                "name": "fieldOperation",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "canRemove"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "canDelete"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "canAssociateInSettings"
                                  }
                                ]
                              },
                              (v9/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "isFieldLocked"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          }
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "__id"
                          }
                        ]
                      }
                    ],
                    "storageKey": "fieldAssociationWithIssueTypes(first:30)"
                  },
                  {
                    "args": (v11/*: any*/),
                    "filters": [
                      "input"
                    ],
                    "handle": "connection",
                    "key": "fieldsTable_fieldAssociationWithIssueTypes",
                    "kind": "LinkedHandle",
                    "name": "fieldAssociationWithIssueTypes"
                  }
                ]
              },
              (v9/*: any*/),
              (v5/*: any*/),
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcistmpprojectfieldsanalyticsupdatesenabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "projectType"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "04a5def9ff0eb06d12d95482cb8856f684c1557a118fcd8066110bdffbd1334d",
    "metadata": {},
    "name": "uiFieldsPageQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcistmpprojectfieldsanalyticsupdatesenabledrelayprovider": isProjectFieldsAnalyticsUpdatesEnabled_provider
    }
  }
};
})();

(node as any).hash = "72c7746a41a19b203b9890c0bf92671f";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
